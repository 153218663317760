
<template>
    <div
      id="forgotm"
      style="min-height: calc(100vh - 20px)"
      class="forgotm-page"
      tag="section"
    >
      <b-row>
        <div class="forgotm-box" no-body>
          <div class="forgotm-wrapper">
            <img src="@/assets/images/venue_booking/logo.svg" id="logo_image_f" />
            <b-form id="forgotm-input-group-1" @submit.prevent="handleSubmit">
              <h2>Account Verify</h2>
              <h6>{{ this.verify_message }}</h6>
              <b-link v-if="show_link" :to="link_path" class="link">
                {{link_name}}
              </b-link>
            </b-form>
          </div>
        </div>
      </b-row>
    </div>
  </template>
      
  
  <script>
  import axios from "axios";
  export default {
    name: "Boxedforgotm",
    
    data: () => ({
        token: "",
        verify_message: "",
        show_link : true,
        link_path: "",
        link_name: "",
    }),
    computed: {},
    validations: {
  
    },
    methods: {
       
        verifyAccount() {
            this.token = this.$route.params.token;
            axios.get("/api/account/verify/"+this.token).then((resp) => {
                if (resp.data.status_code == 200) {
                    if (resp.data.status) {
                      if (localStorage.getItem('authenticated')) {
                        this.link_path = "/dashboard";
                        this.link_name = "Dashboard";
                        this.verify_message = "Your e-mail is already verified.";
                      }else{
                        this.verify_message = resp.data.message;
                        this.link_path = "/login";
                        this.link_name = "Login";
                      }
                    }else{
                      this.show_link = false;
                      this.verify_message = resp.data.message;
                    }
                }
            });
        },
    },
    mounted() {
      
        this.verifyAccount();
    }
  };
  </script>
  <style scoped>
  .forgotm-page {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  #logo_image_f {
     width: 180px;
    /* margin-left: 90px; */
    /* height: 60px; */
    padding-bottom: 15px;
  }
  .forgotm-wrapper {
    text-align: center;
  }
  .forgotm-box {
    background: #fff;
    max-width: 400px;
    width: 100%;
    padding: 30px;
    margin: 8% auto;
  }
  @media screen and (max-width: 475px) {
    .forgotm-box {
      max-width: 100%;
      margin: 0 30px;
    }
  }
  </style>